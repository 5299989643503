.site-btn {
    background-color: var(--primaryColor);
    color: var(--white);
    padding: 14px 30px;
    font-size: 18px;
    line-height: 24px;
    border-radius: 12px;
    display: inline-block;
    cursor: pointer;
    height: auto;
}

.link-btn {
    display: flex;
    align-items: center;
    color: var(--white);
}

.link-btn img {
    font-weight: 500;
    max-width: 17px;
    margin-left: 8px;
}

.primary-text {
    color: var(--primaryColor);
}

.sec-heading {
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*header css start*/
.header {
    padding: 20px 0;
    width: 100%;
    z-index: 2;
    position: sticky;
    top: 0;
    background: var(--white);
}

/*.header.sticky-header {position: fixed; top: 45px; z-index: 10; width: 100%; transition: all .2s ease-in-out 0s; animation-duration: 1s; animation-fill-mode: both;animation-name: slideInDown; box-shadow: 0 3px 10px 4px rgb(0 0 0 / 9%); background: rgba(116, 8, 167, 1);}*/
@keyframes slideInDown {
    0% {
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }

    100% {
        transform: translate3d(0, 0, 0)
    }
}

.header .navbar {
    padding: 0;
}

.navbar-brand img {
    height: 100%;
    max-width: 230px;
    display: block;
}

.footer-details img {
    width: 100%;
    max-width: 180px;
}

.header .navbar .navbar-brand {
    padding: 0;
    margin: 0;
}

.navbar-nav {
    margin-left: auto;
}

.navbar-nav .nav-item {
    padding: 0 32px;
    font-weight: 600;
}

.navbar-nav .nav-item:first-child {
    padding-left: 0;
}

.navbar-nav .nav-item:last-child {
    padding-right: 0;
}

.header .navbar-nav .nav-link {
    padding: 0;
    color: var(--headingColor);
    font-size: 16px;
}

.header .navbar-nav .nav-link:hover,
.header .navbar-nav .nav-link:focus,
.header .nav-item.active .nav-link {
    color: var(--primaryColor);
}

.header .site-btn {
    padding: 11px 42px 12px;
}

/*header css end*/

/* Banner css start */
.banner-inner {
    position: relative;
    background-image: url(../img/main-banner-img.webp);
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 129px);
    font-size: 32px;
    line-height: 42px;
    border-radius: 20px;
}

.banner-inner::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 65%);
    border-radius: 20px;
}

.banner-content {
    position: relative;
    color: var(--white);
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
    z-index: 1;
    font-size: 22px;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.banner-content .h1 {
    margin-bottom: 20px;
}

.banner-content .site-btn {
    padding: 18px 30px;
    margin-top: 38px;
}

/* Banner css end */

/* Top hotel css start */
.hotel-details~#personalizeModal .box {
    padding: 0;
    border-radius: 12px;
    overflow: hidden;
    max-width: 890px;
}

.hotel-details~#personalizeModal .box .close-modal {
    background: #2295A7;
    width: 40px;
    height: 40px;
    color: #fff;
    font-weight: 400;
    padding: 7px 5px 2px;
    border-radius: 40px;
}

.top-hotels-sec {
    padding: 100px 0;
}

.top-hotels-sec .col {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.top-hotel-items {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 20%);
}

.top-hotel-discription {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.top-hotel-list-discription {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 22px;
}

.top-hotel-list-discription p:last-child {
    margin-bottom: 0;
}

.top-hotel-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 45%);
    color: var(--white);
    font-size: 14px;
    line-height: 24px;
    padding: 20px;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    transform: translateY(100%);
    transition: all .3s;
}

.load-more-btn {
    margin: 0 auto;
    display: table;
}

.top-hotel-items:hover .top-hotel-content {
    transform: translateY(0);
}

.top-hotel-content .h6 {
    margin-bottom: 12px;
    color: var(--white);
}

.top-hotel-content p {
    margin-bottom: 20px;
}

.top-hotel-items img {
    width: 100%;
}

/* Top hotel css end */

/* Travel Packages css start */
.travel-packages-slider .slick-list {
    margin: 0 -15px;
}

.travel-packages-items {
    display: flex !important;
    padding: 15px;
    height: 100%;
}

.travel-packages-row {
    display: flex;
    flex-direction: column;
}

.travel-packages-col {
    display: flex;
    width: 100%;
    background: var(--greyColor);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 20%);
    height: 100%;
}

.travel-packages-items .travel-packages-col:first-child {
    margin-bottom: 30px;
    /* height: 100%; */
}

.travel-packages-sec .slick-slide>div {
    height: 100%;
}

.travel-packages-sec .slick-track {
    display: flex;
}

.travel-packages-sec .slick-slide {
    height: auto;
}


.travel-packages-col img {
    min-height: 100%;
    object-fit: cover;
}

.travel-packages-content {
    padding: 25px 20px;
    width: 100%;
}

.tpc-top {
    border-bottom: 1px solid var(--borderColor);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.tpc-top p {
    padding: 10px 0 15px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: normal;
}

.day-tags {
    background: var(--tagBG);
    font-size: 12px;
    padding: 2px 10px;
    line-height: 20px;
    border-radius: 4px;
    color: var(--headingColor);
    margin-left: 8px;
}

.travel-packages-content ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 12px;
    margin-top: 20px;
    margin-bottom: 25px;
}

.travel-packages-content ul li {
    position: relative;
    font-size: 14px;
    line-height: normal;
    padding: 4px 0 4px 12px;
}

.travel-packages-content ul li::before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: var(--primaryColor);
    position: absolute;
    border-radius: 6px;
    left: 0;
    top: 12px;
}

.travel-packages-content a {
    font-weight: 500;
    font-size: 14px;
    text-decoration: underline;
    color: var(--primaryColor);
}

.slick-arrow {
    position: absolute;
    top: -100px;
    right: 0;
    width: 60px;
    height: 60px;
    font-size: 0;
    background-repeat: no-repeat;
    background-size: auto 18px;
    border-radius: 60px;
    background-position: center center;
}

.slick-prev {
    background-image: url(../img/left-black-arrow.webp);
    right: 85px;
}

.slick-prev:hover {
    background-color: var(--primaryColor);
    background-image: url(../img/left-white-arrow.webp);
}

.slick-next {
    background-image: url(../img/right-black-arrow.webp);
}

.slick-next:hover {
    background-color: var(--primaryColor);
    background-image: url(../img/right-white-arrow.webp);
}

/* Travel Packages css end */

/* Contact us section css start */
.contact-sec {
    padding: 120px 0;
}

.contact-inner {
    background-color: var(--greyColor);
    border-radius: 24px;
    overflow: hidden;
}

.contact-form {
    padding: 75px 100px;
    max-width: 833px;
}

.contact-form .h2 {
    margin-bottom: 30px;
}

.contact-form p {
    margin-bottom: 45px;
    font-size: 18px;
    line-height: 30px;
}

input#contact-form-submit {
    width: auto;
}

.content-form .form-group:last-child {
    margin-bottom: 0;
}

.contact-col {
    padding-left: 0;
}

.contact-img {
    height: 100%;
    padding-left: 0;
}

.contact-img img {
    height: 100%;
    object-fit: cover;
}

/* Footer css start */
.footer {
    background-color: var(--headingColor);
}

.footer-logo {
    max-width: 230px;
}

.footer-top {
    padding-top: 35px;
    padding-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(154 154 154 / 25%);
}

.footer-top ul {
    display: flex;
    align-items: center;
    margin: 0 -32px;
}

.footer-top li {
    display: flex;
    align-items: center;
    margin: 0 32px;
}

.footer-top li a {
    color: var(--white);
    font-weight: 500;
}

.footer-top li a:hover,
.footer-top li a.active {
    color: var(--primaryColor);
}


.footer-bottom {
    padding-top: 25px;
    padding-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-bottom ul {
    display: flex;
    align-items: center;
    margin: 0 -32px;
}

.footer-bottom li {
    display: flex;
    align-items: center;
    margin: 0 32px;
}

.footer-bottom li a {
    color: var(--white);
    font-weight: 500;
}

.footer-bottom li a:hover,
.footer-bottom li a.active {
    color: var(--primaryColor);
}

.footer-bottom p {
    margin-bottom: 0;

    color: var(--placeholderColor);
    font-size: 14px;
    line-height: 24px;
}

/* Footer css end */

/* About Page css start */
.inner-page-banner-bg {
    background-image: url(../img/inner-page-banner.webp);
    min-height: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    position: relative;
}

.inner-page-banner-bg::before {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 60%);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

.inner-page-banner-bg .h1 {
    z-index: 1;
    text-align: center;
    padding: 0 60px;
    line-height: 74px;
}

.about-sec {
    padding: 130px 0;
}

.about-img {
    padding-right: 35px;
}

.about-img img {
    padding: 17px;
    border: 1px solid var(--primaryColor);
    border-radius: 200px 20px 20px 200px;
}

.about-content {
    padding-left: 15px;
}

.about-content .h2 {
    margin-bottom: 30px;
}

.why-us-content {
    padding-right: 100px;
}

.why-us-content .h5 {
    margin-bottom: 8px;
    display: block;
    font-weight: 400;
}

.why-us-content .h2 {
    margin-bottom: 23px;
}

.why-us-tag {
    font-weight: 600;
    line-height: normal;
    padding: 10px 15px;
    border-radius: 22px;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 23px;
    color: var(--headingColor);
}

.why-us-item:first-child .why-us-tag {
    background-color: #B8D8BA;
}

.why-us-item p:first-letter {
    text-transform: capitalize;
}

.pl-tag {
    background-color: #D9DBBC;
}

.why-us-item:last-child .why-us-tag {
    background-color: #FCDDBC;
}

.why-us-img {
    text-align: right;
}

.why-us-img img {
    border-radius: 20px;
}

.why-us-sec {
    padding-bottom: 130px;
}

/* About page css end */

/* Contact us page css start */
.contact-page-sec {
    padding: 130px 0;
}

.contact-page-sec .contact-form {
    padding: 0 85px 0 0;
}

.contact-page-sec .contact-inner {
    background: transparent;
    border-radius: 0;
    overflow: visible;
}

.contact-page-sec .contact-img {
    border-radius: 20px;
    overflow: hidden;
}

/* Contact us page css end */

/* Top Hotels page css start */
.top-hotel-list-sec {
    padding-top: 130px;
    padding-bottom: 90px;
}

.top-hotel-list-items {
    background-color: var(--greyColor);
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 40px;
    box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 20%);
}

.tp-list-img {
    height: 100%;
}

.tp-list-img img {
    height: 100%;
    object-fit: cover;
}

.tp-list-img-col {
    padding-right: 0;
}

.tp-list-content {
    padding: 35px 45px 35px 35px;
}

.tp-list-content .h4 {
    line-height: normal;
    margin-bottom: 6px;
}

.reviews {
    display: flex;
    align-items: center;
}

.reviews-star {
    display: flex;
    align-items: center;
    margin: 0 -1px;
    margin-right: 8px;
}

.reviews img {
    margin: 0 1px;
}

.reviews-star span {
    font-size: 14px;
    line-height: normal;
}

.tp-list-content .primary-text {
    margin-bottom: 8px;
    margin-top: 20px;
    display: block;
}

.listing-tags {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    flex-wrap: wrap;
}

.listing-tags li {
    background: rgb(34 149 167 / 15%);
    margin-right: 8px;
    padding: 3px 12px;
    border-radius: 4px;
    font-size: 14px;
    line-height: normal;
    display: flex;
    align-items: center;
    color: var(--headingColor);
    margin-bottom: 8px;
}

.listing-tags li svg {
    margin-right: 5px;
}

.tp-list-content .site-btn {
    margin-top: 8px;
}

/* Top Hotels page css end */

/* Hotel Details page css start */
.hotel-details {
    padding: 130px 0 118px;
}

.hotel-details-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.hotel-details-top .h2 {
    margin-bottom: 4px;
}

.hotel-details-top .reviews {
    margin-bottom: 20px;
}

/* .hotel-location img {
    margin-right: 8px;
}

.hotel-details-imgs {
    flex-wrap: initial;
    margin-bottom: 40px;
}

.hotel-col {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
}

.hotel-details-imgs-right img:not(:last-child) {
    margin-bottom: 30px;
}

.hotel-details-imgs img {
    border-radius: 20px;
} */

.hotel-location img {
    margin-right: 8px;
}

.hotel-details-imgs-main {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
}

.hotel-details-imgs {
    margin: -15px;
}

.hotel-details-imgs .hotel-col {
    float: left;
    width: 36%;
    padding: 15px;
}

.hotel-details-imgs .hotel-col:first-child {
    width: 64%;
}

.hotel-details-imgs .hotel-col:first-child img {
    max-height: 100%;
}

.hotel-details-imgs .hotel-col img {
    max-height: calc(340px + 7.5px);
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.highlight-text {
    font-weight: 500;
    margin-top: 8px;
    display: inline-block;
    color: var(--headingColor);
}

.popular-facilities {
    margin-top: 33px;
    margin-bottom: 45px;
}

.hotel-details .h5 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 15px;
}

.popular-facilities {
    max-width: 960px;
}

.popular-facilities ul {
    display: flex;
    flex-wrap: wrap;
}

.popular-facilities ul li {
    margin-right: 60px;
    display: flex;
    align-items: center;
    margin: 10px 60px 10px 0;
}

.popular-facilities ul li svg {
    margin-right: 12px;
}

.nearby-place table {
    width: 500px;
}

.nearby-place table td {
    padding: 0 0 12px;
}

/* Hotel Details page css end */

/* Contect page css start */
.content-page {
    padding: 117px 0 108px;
}

.content-page .h4 {
    margin-bottom: 22px;
    margin-top: 13px;
    display: inline-block;
    width: 100%;
}

.content-page .h5,
.content-page .h6 {
    margin-bottom: 22px;
}

.content-page ul {
    margin-bottom: 22px;
}

.content-page li {
    position: relative;
    padding-left: 16px;
}

.content-page li:not(:last-child) {
    margin-bottom: 4px;
}

.content-page li::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: var(--primaryColor);
    left: 0;
    top: 10px;
}

/* Contect page css end */

/* 404 page css start */
.error-404-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 25px 100px;
    min-height: 76.5vh;
    /* background: #f7f7f7; */
}

.error-404-sec .site-btn {
    margin-top: 45px;
}

/* 404 page css end */

/* Modal css start */
.modal {
    position: fixed;
    top: 0px;
    pointer-events: none;
    left: 0px;
    background: rgba(70, 70, 70, 0.9);
    width: 100vw;
    height: 100vh;
    z-index: 100;
    opacity: 0;
    transition: 0.3s;
}

.modal.active {
    opacity: 1;
    pointer-events: auto;
}

.modal.active .box {
    top: 50%;
}

.modal .h4 {
    margin-bottom: 35px;
    text-align: left;
}

.modal .close {
    position: absolute;
    top: 12px;
    right: 15px;
    cursor: pointer;
    color: rgba(136, 140, 173, 0.88);
    font-family: 'Mont' !important;
    font-size: 24px;
    font-weight: 600;
}

.modal .box {
    background: var(--white);
    display: block;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    padding: 40px 60px;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    width: 100%;
    max-width: 580px;
    color: #fff;
}

/* Modal Css End */

/* Responsive css start */
@media (max-width: 1700px) {
    .travel-packages-content ul {
        grid-template-columns: repeat(2, 1fr);
    }

    .contact-form {
        padding: 50px 60px;
        max-width: 833px;
    }
}

@media (max-width: 1441px) {
    .hotel-details-imgs .hotel-col img {
        max-height: calc(310px + 7.5px);
    }
}

@media (max-width: 1400px) {

    .h2,
    h2 {
        font-size: 42px;
        line-height: 54px;
    }

    .hotel-details-imgs .hotel-col img {
        max-height: calc(291px + 7.5px);
    }
}

@media (max-width: 1300px) {
    .top-hotels-sec .col {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .top-hotels-sec .row {
        justify-content: center;
    }

    .hotel-details-imgs .hotel-col img {
        max-height: calc(269px + 7.5px);
    }
}

@media (max-width: 1200px) {
    .top-hotels-sec .col {
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }

    .contact-form {
        padding: 50px 60px;
        max-width: 100%;
    }

    .contact-inner .row {
        flex-direction: column-reverse;
    }

    .contact-col {
        padding-left: 15px;
    }

    .contact-img img {
        width: 100%;
        border-radius: 24px;
    }

    .about-img,
    .why-us-content {
        padding-right: 0;
    }

    .tp-list-img img {
        width: 100%;
        height: auto;
    }

    .hotel-details-top .site-btn {
        margin-left: 25px;
        max-width: 148px;
        flex: 1 0 auto;
        text-align: center;
    }

    .hotel-details-imgs-left img {
        height: 100%;
        object-fit: cover;
    }

    .popular-facilities ul li {
        width: 33.33%;
        margin-right: 0;
    }

    .contact-page-sec {
        padding: 80px 0;
    }

    .contact-page-sec .contact-form {
        padding: 50px 0 0;
    }

    .content-page {
        padding: 80px 0 68px;
    }

    .hotel-details-imgs .hotel-col img {
        max-height: calc(244px + 7.5px);
    }
}

@media (max-width: 1025px) {
    .hotel-details-imgs .hotel-col img {
        max-height: calc(204px + 7.5px);
    }

    .footer-bottom li {
        margin: 0 20px;
    }
}

@media (max-width: 991px) {

    .h1,
    h1 {
        font-size: 48px;
    }

    .h2,
    h2 {
        font-size: 32px;
        line-height: 44px;
    }

    .site-btn {
        padding: 14px 25px;
        font-size: 16px;
        line-height: 20px;
    }

    .sec-heading {
        margin-bottom: 25px;
    }

    .slick-arrow {
        top: -68px;
        width: 48px;
        height: 48px;
        background-size: auto 14px;
        border-radius: 48px;
    }

    .slick-prev {
        right: 60px;
    }

    .open-menu {
        overflow: hidden !important;
        height: 100vh;
    }

    .header {
        padding: 25px 0;
        z-index: 99;
    }

    header.header.sticky-header {
        position: fixed;
    }

    .navbar-brand img {
        max-height: 42px;
    }

    .navbar {
        display: flex;
        position: initial;
    }

    .header .navbar .navbar-brand {
        padding: 0;
        margin: 0;
        z-index: 9;
        max-width: 210px;
    }

    .navbar-toggler {
        width: 25px;
        display: flex;
        flex-direction: column;
        height: 19px;
        justify-content: space-between;
        padding: 0;
        cursor: pointer;
        z-index: 9;
    }

    .toggler-icon {
        width: 27px;
        height: 3px;
        background: var(--primaryColor);
        display: inline-block;
        border-radius: 0;
        padding: 0 !important;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        border-radius: 3px;
    }

    .navbar-toggler.collapsed .toggler-icon:nth-of-type(1) {
        transform: rotate(0);
    }

    .navbar-toggler .toggler-icon:nth-of-type(1) {
        transform: rotate(45deg);
        transform-origin: 20% 2px;
    }

    .navbar-toggler.collapsed .toggler-icon:nth-of-type(2) {
        opacity: 1;
        filter: alpha(opacity=100);
    }

    .navbar-toggler .toggler-icon:nth-of-type(2) {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    .navbar-toggler.collapsed .toggler-icon:nth-of-type(3) {
        transform: rotate(0);
    }

    .navbar-toggler .toggler-icon:nth-of-type(3) {
        transform: rotate(-45deg);
        transform-origin: 10% 0px;
    }

    .navbar-collapse {
        position: absolute;
        width: 100%;
        height: 100vh !important;
        z-index: 3;
        top: 0;
        left: 0;
        background-color: var(--white);
        transform: translateX(-100%);
        opacity: 0;
        transition: all .4s;
        overflow-y: auto;
        padding-bottom: 35px;
    }

    .open-menu .navbar-collapse {
        transform: translateX(0);
        opacity: 1;
    }

    .navbar-collapse ul.navbar-nav {
        padding: 120px 45px 38px;
        gap: 0;
    }

    .header .site-btn {
        margin-left: 45px;
    }

    .header .navbar-nav .nav-item {
        padding: 26px 0 4px;
        display: inline-flex;
        width: 100%;
    }

    .header .navbar-nav .nav-link {
        font-size: 22px;
        line-height: 30px;
        color: var(--headingColor);
    }

    .banner-inner {
        height: 600px;
    }

    .banner-content {
        padding: 0 40px;
    }

    .top-hotels-sec {
        padding: 80px 0 65px;
    }

    .top-hotels-sec .col {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .travel-packages-row {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .travel-packages-col {
        height: 100%;
        display: inline-table;
    }

    .contact-sec {
        padding: 65px 0 80px;
    }

    .footer-bottom {
        flex-direction: column;
    }

    .footer-logo img {
        max-height: 42px;
    }

    .footer-top li {
        margin: 0 12px;
    }

    .footer-bottom ul {
        margin-bottom: 20px;
    }

    .inner-page-banner-bg {
        min-height: 360px;
    }

    .about-sec {
        padding: 80px 0;
    }

    .about-content {
        padding-left: 0px;
    }

    .about-img img,
    .why-us-img img {
        width: 100%;
        border-radius: 20px;
        margin-top: 18px;
    }

    .about-sec .row {
        flex-direction: column-reverse;
    }

    .why-us-sec {
        padding-bottom: 80px;
    }

    .top-hotel-list-sec {
        padding-top: 80px;
        padding-bottom: 40px;
    }

    .hotel-details {
        padding: 80px 0 68px;
    }

    .inner-page-banner-bg .h1 {
        line-height: 62px;
    }

    .popular-facilities ul li {
        width: 50%;
        margin-right: 0;
    }

    .contact-page-sec {
        padding-top: 80px;
    }

    .hotel-details-imgs .hotel-col:first-child {
        width: 100%;
    }

    .hotel-details-imgs .hotel-col {
        width: 50%;
    }

    .hotel-details-imgs .hotel-col img {
        max-height: initial;
    }

    .travel-packages-col img {
        min-height: auto;
        width: 100%;
    }

    .error-404-sec {
        min-height: 83vh;
    }

    .error-404-sec img {
        max-width: 75%;
    }
}


@media (max-width: 767px) {

    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        padding-right: 20px;
        padding-left: 20px;
    }

    .h1,
    h1 {
        font-size: 32px;
        line-height: 42px;
    }

    .h2,
    h2 {
        font-size: 28px;
        line-height: 40px;
    }

    .banner-inner {
        height: 480px;
    }

    .navbar-brand img {
        max-height: 32px;
    }

    .banner-content {
        padding: 0 20px;
        font-size: 18px;
    }

    .banner-content .site-btn {
        padding: 16px 25px;
        margin-top: 18px;
    }

    .travel-packages-sec .sec-heading {
        margin-bottom: 0;
    }

    .contact-form {
        padding: 30px 20px;
        max-width: 100%;
    }

    .contact-form .h2 {
        margin-bottom: 22px;
    }

    .footer-top {
        flex-direction: column;
        padding-bottom: 35px;
    }

    .footer-logo img {
        max-height: 32px;
    }

    .footer-top .footer-logo {
        margin-bottom: 25px;
    }

    .footer-bottom p {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .inner-page-banner-bg {
        min-height: 260px;
    }

    .about-sec {
        padding: 80px 0;
    }

    .about-content {
        padding-left: 0;
    }

    .about-img {
        padding-right: 0;
    }

    .about-img img {
        border-radius: 20px;
        width: 100%;
        margin-top: 18px;
    }

    .about-sec .row {
        flex-direction: column-reverse;
    }

    .why-us-sec {
        padding-bottom: 80px;
    }

    .why-us-content {
        padding-right: 0;
    }

    .why-us-img img {
        width: 100%;
        margin-top: 20px;
    }

    /* .hotel-details-imgs {
        flex-wrap: wrap;
    }

    .hotel-col {
        flex-direction: initial;
        flex: initial;
        display: initial;
        margin: 0 -8px;
    }

    .hotel-details-imgs-left img {
        padding: 8px;
    }

    .hotel-details-imgs-right {
        display: flex;
    } */

    .hotel-details-imgs-main {
        margin-bottom: 25px;
    }

    .hotel-details-imgs {
        margin: -8px;
    }

    .hotel-details-imgs .hotel-col {
        padding: 8px;
    }

    .hotel-details-imgs-right img {
        width: 50%;
        margin-bottom: 0 !important;
        padding: 8px;
    }

    .popular-facilities ul li {
        width: 100%;
        display: block;
        margin: 8px 0;
    }

    .nearby-place table tr {
        padding-bottom: 10px;
        display: block;
    }

    .nearby-place table td {
        padding: 0 0 12px;
        width: 100%;
        flex-wrap: wrap;
        display: block;
        line-height: 20px;
    }

    .hotel-details-top {
        flex-direction: column;
        align-items: flex-start;
    }

    .hotel-details-top .site-btn {
        margin-left: 0;
        margin-bottom: 20px;
    }

    .popular-facilities {
        margin-top: 20px;
        margin-bottom: 35px;
    }

    .modal .box {
        width: calc(100% - 40px);
        padding: 30px 20px 20px;
    }

    .modal .h4 {
        text-align: left;
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 27px;
    }

    .modal .close {
        top: 6px;
        right: 12px;
    }

    .error-404-sec {
        min-height: auto;
        padding: 60px 25px 80px;
    }

    .error-404-sec img {
        max-width: 100%;
    }
}

@media (max-width: 480px) {

    .h2,
    h2 {
        font-size: 26px;
        line-height: 36px;
        line-height: 40px;
    }

    .h4,
    h4 {
        font-size: 20px;
    }

    .sec-heading {
        margin-bottom: 25px;
        flex-direction: column;
        align-items: flex-start;
    }

    .sec-heading .h2 {
        margin-bottom: 20px;
    }

    .sec-heading .site-btn {
        margin-right: auto;
    }

    .top-hotels-slider {
        padding-bottom: 60px;
    }

    .top-hotels-sec .col {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .travel-packages-content ul {
        grid-template-columns: repeat(1, 1fr);
    }

    .travel-packages-slider {
        padding-bottom: 60px;
    }

    .slick-arrow {
        top: initial;
        bottom: 0;
    }

    .slick-prev {
        left: calc(50% - 56px);
    }

    .slick-next {
        right: calc(50% - 56px);
    }

    .footer-bottom {
        flex-direction: column;
        text-align: center;
    }

    .footer-top ul,
    .footer-bottom ul {
        flex-direction: column;
    }

    .footer-bottom p {
        padding-bottom: 0;
    }

    .footer-top li,
    .footer-bottom li {
        margin: 0 0 8px;
    }

    .footer-top li:last-child {
        margin-bottom: 0;
    }


    .contact-sec {
        padding: 45px 0 60px;
    }

    .top-hotels-sec {
        padding: 60px 0 45px;
    }

    .inner-page-banner-bg {
        min-height: 220px;
    }

    .about-sec {
        padding: 60px 0;
    }

    .why-us-sec {
        padding-bottom: 60px;
    }

    .why-us-tag {
        margin-top: 18px;
    }

    .tp-list-content {
        padding: 25px 20px;
    }

    .tp-list-content .h4 {
        line-height: 26px;
        margin-bottom: 8px;
    }

    .listing-tags {
        margin-bottom: 17px;
        flex-wrap: wrap;
    }

    .listing-tags li {
        margin-bottom: 8px;
    }

    .inner-page-banner-bg .h1 {
        line-height: normal;
        padding: 0 15px;
    }

    .hotel-details {
        padding: 60px 0 48px;
    }

    .nearby-place table {
        width: 100%;
    }

    .contact-page-sec {
        padding-top: 60px;
    }

    .content-page {
        padding: 60px 0 38px;
    }
}



#toast-container {
    margin-top: 10px;
}

.custom-toast {
    background-color: #28a745;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 14px;
    display: inline-block;
    transition: opacity 0.5s ease;
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}